<template>
  <div v-if=" isWechat == false " style="text-align: center;margin-top: 10rem">请在微信中登录</div>
  <div v-else style="text-align: center; margin-top: 10rem">
    登录中..........
  </div>
  <div style="text-align: center;color: red">
    {{ message }}
  </div>
</template>

<script>
import * as loginApi from "../../api/login";
import { Toast } from "@nutui/nutui";
import utils from "../../utils";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";

export default {
  setup() {
    const isWechat = ref(true);
    const code = useRoute().query.code;
    const router = useRouter();
    // const token = utils.getToken();
    const message = ref("");

    // if( token!= null && token.length == 32 ){
    //   router.push('/');
    //   return false;
    // }
    /**
     * 判断是否是微信环境
     */
    var ua = navigator.userAgent.toLowerCase();
    if(ua.match(/MicroMessenger/i) == 'micromessenger'){
      // 是微信环境
      if( code == undefined ){
        loginApi.getJumpUrl().then((res) => {
          if( res.code == 0 ){
            window.location.href = res.data.redirect_uri;
          } else {
            Toast.fail(res.message);
          }
        });
      } else {
        loginApi.doLogin({ code: code }).then((res) => {
          if( res.code == 0 ){
            utils.setToken(res.data.token);
            router.push('/')
          } else {
            message.value =res.message;
          }
        });
      }
    }else{
      isWechat.value = true;
    }

    return {
      isWechat,
      message
    };
  },
}
</script>

<style scoped>

</style>